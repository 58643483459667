<template>
  <b-modal
    ref="googleIdModal"
    id="googleProfileIdModal"
    centered
    hide-footer
    hide-header
    size="xl"
    body-class="p-0"
  >
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="card-header-title" id="exampleModalCenterTitle">We need some information</h3>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div>
          In order to respond to Google reviews for your
          <b>{{ location.friendlyName || location.city }}</b> location, we need your Google business
          profile ID.
        </div>
        <button class="btn btn-link px-0" @click="openHelpLink">
          Click here to learn where to get this ID
        </button>

        <div class="text-center mt-4 mb-5">
          <!-- text input -->
          <div class="form-group mx-5">
            <label>
              Business Profile ID for <b>{{ location.friendlyName || location.city }}</b>
            </label>
            <input v-model="profileId" type="text" class="form-control" placeholder="Profile Id" />
          </div>
        </div>

        <div class="text-right">
          <button
            class="btn btn-primary w-25"
            @click="saveProfileId"
            :disabled="!profileId || saving"
          >
            <span v-if="!saving">Save</span>
            <b-spinner small variant="light" v-else />
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const ReviewSubscriptionModel = createNamespacedHelpers('reviewSubscription')

export default {
  name: 'GoogleProfileIdModal',
  props: {
    location: {
      type: Object,
      required: true,
    },
    reviewSubscription: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    profileId: '',
    saving: false,
    helpLink:
      'https://intercom.help/ovation-bd4a0805515d/en/articles/6977685-how-to-find-my-google-business-profile-id',
  }),
  mounted() {
    this.profileId = this.reviewSubscription?.googleBusinessProfileId || ''
  },
  methods: {
    ...ReviewSubscriptionModel.mapActions(['updateReviewSubscription']),
    onCreatePromo(promo) {
      this.$emit('added', promo)
      this.close()
    },
    openHelpLink() {
      window.open(this.helpLink, '_blank')
    },
    async saveProfileId() {
      this.saving = true
      await this.updateReviewSubscription({
        reviewSubscriptionId: this.reviewSubscription._id,
        locationId: this.location._id,
        modifications: {
          googleBusinessProfileId: this.profileId,
        },
      })
      this.$emit('save', this.profileId)
      this.close()
      this.saving = false
    },
    open() {
      this.$refs.googleIdModal.show()
    },
    close() {
      this.$refs.googleIdModal.hide()
    },
  },
}
</script>
