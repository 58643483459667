<template>
  <div class="card p-4">
    <div class="row align-items-center">
      <!-- product info -->
      <div class="col-4">
        <div class="d-flex align-items-center">
          <div v-if="planNames.length === 1">{{ planNames[0] }}</div>
          <div
            class="h3 m-0"
            v-else-if="planNames.length > 1"
            v-b-popover.top.hover="planNames.join(', ')"
          >
            {{ planNames[0] }}
            <span>and {{ planNames.length - 1 }} more</span>
          </div>
          <div>
            <span class="badge text-capitalize ml-2" :class="badgeColor">
              {{ subscription.status }}
            </span>
          </div>
        </div>
        <div v-if="nextInvoice" class="invoice-date-text">
          {{ nextInvoice }}
        </div>
      </div>

      <!-- locations -->
      <div class="col-3">
        <span v-if="locations.length === 1">
          {{ locations[0] }}
        </span>
        <span v-else-if="locations.length > 1" v-b-popover.top.hover="locations.join(', ')">
          {{ locations[0] }}
          <span v-if="locations.length > 1">+{{ locations.length - 1 }} more</span>
        </span>
        <div v-else>All locations</div>
      </div>

      <!-- card info -->
      <div class="col-3">
        <div
          class="d-flex align-items-center"
          v-if="!isSendInvoice && subscription.stripe.defaultPaymentMethod"
        >
          <img class="mr-3" style="border-radius: 3px; width: 35px" :src="paymentImgLink" />
          <span class="mr-2">••••</span>
          <span>{{ cardLast4 }}</span>
        </div>
      </div>

      <!-- buttons -->
      <div class="col-2" v-if="!isSendInvoice && subscription.stripe.subscription">
        <button class="btn btn-primary btn-sm" style="width: 100px" @click="openSession">
          <span v-if="!loadingSession"> Details </span>
          <span v-else>
            <b-spinner small />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const SubscriptionModule = createNamespacedHelpers('subscription')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'SubscriptionCard',
  props: ['subscription'],
  data: () => ({
    loadingSession: false,
  }),
  computed: {
    ...LocationModule.mapGetters(['selectLocationName']),
    planNames() {
      return this.subscription.stripe?.products.map((p) => p.name)
    },
    badgeColor() {
      if (this.subscription.status === 'active') {
        return 'badge-soft-success'
      } else if (this.subscription.status === 'pending') {
        return 'badge-soft-primary'
      } else if (['paused', 'incomplete']) {
        return 'badge-soft-warning'
      } else {
        return 'badge-soft-danger'
      }
    },
    isSendInvoice() {
      return this.subscription.stripe.subscription?.collection_method === 'send_invoice'
    },
    cost() {
      return this.subscription.stripe.subscription?.items.data.reduce((acc, curr) => {
        return acc + (curr.price?.unit_amount || 0) * curr.quantity
      }, 0)
    },
    nextInvoice() {
      const periodEnd = this.subscription.stripe.subscription?.current_period_end
      return periodEnd
        ? `Next invoice on ${this.$moment.unix(periodEnd).format('MMM D')} for $${this.cost / 100}`
        : ''
    },
    locations() {
      return this.subscription.locations?.map((l) => this.selectLocationName(l)) || []
    },
    cardLast4() {
      const defaultPaymentMethod = this.subscription.stripe.defaultPaymentMethod
      const type = defaultPaymentMethod?.type
      return defaultPaymentMethod ? defaultPaymentMethod[type].last4 : null
    },
    paymentImgLink() {
      const type = this.subscription.stripe.defaultPaymentMethod?.type
      if (!type) return

      if (type === 'us_bank_account') {
        return `https://assets/payment-processors/${bank}.png`
      } else {
        const availableCardImages = ['visa', 'mastercard', 'amex', 'discover']
        const brand = this.subscription.stripe.defaultPaymentMethod?.card.brand
        const img = availableCardImages.includes(brand) ? brand : 'card'
        return `https://assets/payment-processors/${img}.png`
      }
    },
  },
  methods: {
    ...SubscriptionModule.mapActions(['createSession']),
    async openSession() {
      this.loadingSession = true
      const sessionUrl = await this.createSession({
        subscriptionId: this.subscription._id,
        returnUrl: `https://app.ovationup.com${this.$route.fullPath}`,
      })
      window.open(sessionUrl, '_blank')
      this.loadingSession = false
    },
  },
}
</script>

<style scoped>
.invoice-date-text {
  color: #818181;
}
</style>
